<template>
  <b-card class="blog-edit-wrapper" :title="formTitle">
    <!-- form -->
    <validation-observer ref="convocatoriaRules">
      <b-form class="mt-2">
        <b-row>
          <b-col md="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="text-center">
                <b>Foto Portada</b>
              </h4>
              <div class="text-center">
                <b-form-group label="" label-for="">
                  <div class="box-imagen box-imagen1 border rounded">
                    <b-img
                      v-if="imagen"
                      :src="imagen"
                      center
                      fluid
                      thumbnail
                      class="rounded"
                      alt="Card image cap"
                    />
                  </div>
                </b-form-group>
                <b-button
                  v-b-modal.modal-seleccionar-imagen
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-middle">Elegir Imagen</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col md="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1 text-center"><b>Foto Portada</b></h4>
              <div class="text-center">
                <b-img
                  v-if="imagen"
                  ref="refPreviewEl"
                  :src="imagen"
                  height="190"
                  width="500"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-img
                  v-else
                  ref="refPreviewEl"
                  :src="convocatoria.foto_portada"
                  height="190"
                  width="500"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-form-file
                  ref="refInputEl"
                  v-model="convocatoriaFile"
                  accept=".jpg, .png, .gif"
                  placeholder="Cambiar Fotov"
                  @input="inputImageRenderer"
                />
              </div>
            </div>
          </b-col> -->
          <b-col md="12">
            <b-form-group
              
              label-for="titulo"
              class="mb-2"
            >
            <label for="titulo">Titulo Convocatoria <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Titulo convocatoria"
                rules="required"
              >
                <b-form-input
                  id="titulo"
                  v-model="convocatoria.titulo"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.titulo"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Url">
              <label for="url">Url <span class="text-danger">(*)</span></label>
              <b-form-input
                id="url"
                disabled
                v-model="convocatoria.url"
                placeholder="Url"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label-for="Fecha Publicacion"
            >
            <label for="Fecha Publicacion">Fecha Publicación <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Fecha Publicacion"
                rules="required"
              >
                <flat-pickr
                  v-model="convocatoria.published_at"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: 'es'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.published_at"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Estado" label-for="Estado">
              <v-select
                id="blog-edit-estado"
                v-model="estadoa"
                label="title"
                :disabled="!$hascan('convocatoria.publicar')"
                :options="estadoOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- Descripcion -->
            <b-form-group label-for="descripcion">
              <label for="Descripcion">Descripción</label>
              
              <CkEditorPersonalizado
                ref="ckeditor"
                v-model="convocatoria.descripcion"
                
              />
              <!-- <b-form-textarea
                  id="descripcion"
                  v-model="convocatoria.descripcion"
                  placeholder="Descripcion"
                  rows="3"
                  :state="errors.length > 0 ? false : null"
                />
                
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.descripcion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                > -->
              <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label-for="">
              <label for="Contenido">Contenido</label>
              <b-button
                v-b-modal.modal-seleccionar-archivo
                variant="flat-dark"
                size="sm"
              >
                <feather-icon icon="ImageIcon" class="mr-50" />
                <span class="align-left">Medios</span>
              </b-button>
              
              <validation-provider
                #default="{ errors }"
                name="Contenido"
                rules="required"
              >
                <CkEditorPersonalizado
                  ref="ckeditor"
                  v-model="convocatoria.contenido"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.contenido"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="pt-2">Fechas de Recepcion</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="fecha" class="mb-2">
              <label for="fecha">Fecha Inicio <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Fecha Inicio"
                rules="required"
              >
                <b-form-datepicker
                  id="fecha"
                  v-model="convocatoria.fecha_inicio"
                  placeholder="Ingrese la Fecha Inicio"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.fecha_inicio"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              
              label-for="fecha_final"
              class="mb-2"
            >
            <label for="fecha_final">Fecha Final <span class="text-danger">(*)</span></label>
            
              <validation-provider
                #default="{ errors }"
                name="Fecha Final"
                rules="required"
              >
                <b-form-datepicker
                  id="fecha_final"
                  v-model="convocatoria.fecha_final"
                  placeholder="Ingrese la Fecha Final"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.fecha_final"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3>Detalle del Lugar Presentación</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              
              label-for="nombre_lugar"
              class="mb-2"
            >
            <label for="nombre_lugar">Nombre del Lugar 
              <!-- <span class="text-danger">(*)</span> -->
            </label>
              <b-form-input
                  id="nombre_lugar"
                  v-model="convocatoria.nombre_lugar"
                  
                />
                <!-- :state="errors.length > 0 ? false : null" -->
              <!-- <validation-provider
                #default="{ errors }"
                name="Nombre del lugar"
                rules="required"
              >
                
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre_lugar"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="direccion" class="mb-2">
              <label for="direccion">Dirección 
                <!-- <span class="text-danger">(*)</span> -->
              </label>
              <b-form-input
                  id="direccion"
                  v-model="convocatoria.direccion_presentacion"
                  
              />
              <!-- :state="errors.length > 0 ? false : null" -->
              <!-- <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="required"
              >
                
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.direccion_presentacion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider> -->
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="">Mas Informaciones</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Teléfono"
              label-for="telefono_informes"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefono de Informaciones"
                rules=""
              >
                <b-form-input
                  id="telefono_informes"
                  v-model="convocatoria.telefono_informes"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono_informes"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="URL Documento"
              label-for="url_documento"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Documento"
                rules="url"
              >
                <b-input-group>
                  <b-form-input
                    id="url_documento"
                    v-model="convocatoria.url_documento"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append>
                    <b-button
                      v-b-modal.modal-seleccionar-documento
                      variant="outline-primary"
                    >
                      <feather-icon icon="LinkIcon" class="mr-50" />
                      <span class="align-left"> </span
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_documento"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="URL Formulario"
              label-for="url_formulario"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Formulario"
                rules="url"
              >
                <b-form-input
                  id="url_formulario"
                  v-model="convocatoria.url_formulario"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_formulario"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="validar"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'convocatorias' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row />

    <!--/ form -->
    <!--Componente Medios -->
    <MediosComponent @enviarMedia="recibirMedia"></MediosComponent>
    <!--Componente Imagen -->
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
    <DocumentoComponent
      @enviarDocumento="recibirDocumento"
    ></DocumentoComponent>
  </b-card>
</template>

<script>
import axios from "@axios";
import {
  required,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import {
  BFormCheckbox,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado";
import MediosComponent from "@/componentes/MediosComponent.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import DocumentoComponent from "@/componentes/DocumentoComponent.vue";
import flatPickr from "vue-flatpickr-component";
import router from "@/router";

const Spanish = require('flatpickr/dist/l10n/es');
// flatpickr.localize(Spanish);

localize("es", es);

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    CkEditorPersonalizado,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    MediosComponent,
    ImagenComponent,
    DocumentoComponent,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      errores: [],
      selectedForm: [],
      estadoOpciones: [
        { id: 1, title: "Publicado" },
        { id: 2, title: "Borrador" },
        { id: 3, title: "Papelera" },
      ],
      estadoa: { id: 2, title: "Borrador" },

      convocatoria: {
        nombre: "",
        direccion: "",
        telefono: "",
        telefono_organizador: "",
        url_facebook: "",
        url_instagram: "",
        url_youtube: "",
        url_documento: "",
        fax: "",
        hora_inicio: "",
        hora_final: "",
        fecha_inicio:"",
        fecha_final:"",
        categoria_id: 1,
        published_at: null,
      },
      convocatoriaFile: null,
      imagenMiniatura: "",
      convocatoriaOption: [],
      selected: "0",
      selectedFormulario: "0",      
    };
  },
  computed: {
    formTitle() {
      return this.convocatoria.entrada_id
        ? "Editar Convocatoria"
        : "Nueva Convocatoria";
    },
  },
  watch: {
    "convocatoria.nombre": function () {
      this.errores.nombre = [];
    },
    "convocatoria.nombre_fiscal": function () {
      this.errores.nombre_fiscal = [];
    },
    "convocatoria.direccion": function () {
      this.errores.direccion = [];
    },
    "convocatoria.telefono": function () {
      this.errores.telefono = [];
    },
    "convocatoria.fax": function () {
      this.errores.fax = [];
    },
    "convocatoria.ubicacion": function () {
      this.errores.ubicacion = [];
    },
    "ubicacion.position.lat": function () {
      this.errores.latitud = [];
    },
    "ubicacion.position.lng": function () {
      this.errores.longitud = [];
    },
  },
  created() {
    this.date();

    if (router.currentRoute.params.id) {
      axios
        .get(`/convocatorias/${router.currentRoute.params.id}`)
        .then((response) => {
          this.convocatoria = response.data.data;
          this.convocatoria.categoria_id =
            this.convocatoria.entrada.categoria_id;
            // var fecha= this.convocatoria.fecha_inicio;
            // var fec=fecha.split("-");
            // //console.log(fec)
            // this.convocatoria.fecha_inicio=new Date(fec[2]+"-"+fec[1]+"-"+fec[0]);
            // var fecha1= this.convocatoria.fecha_final;
            // var fec1=fecha1.split("-");
            // this.convocatoria.fecha_final=new Date(fec1[2]+"-"+fec1[1]+"-"+fec1[0]);

          this.convocatoria.titulo = this.convocatoria.entrada.titulo;
          this.convocatoria.url = this.convocatoria.entrada.url;
          this.imagen = this.convocatoria.entrada.imagen;
          this.convocatoria.published_at =
            this.convocatoria.entrada.published_at;
          this.convocatoria.descripcion = this.convocatoria.entrada.descripcion;
          this.convocatoria.contenido = this.convocatoria.entrada.contenido;
          this.estadoa.id = this.convocatoria.entrada.estado;
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === this.convocatoria.entrada.estado
          );
          this.estadoa = { id: valor.id, title: valor.title };
        })
        .catch((error) => console.error(error));
    }
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const imagen = ref("");

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
        imagen.value = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      imagen,
    };
  },

  methods: {
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "convocatorias" });
    },
    async getAllconvocatorias() {
      await axios
        .get("/getAllconvocatoriasSelect")
        .then((response) => {
          this.convocatoriaOption = response.data.data;
        })
        .catch((error) => reject(error));
    },

    traerconvocatorias() {
      if (this.selected == "1") this.getAllconvocatorias();
    },

    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`);
    },
    validar() {
      this.$refs.convocatoriaRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    async guardar() {
      this.convocatoria.imagen = this.imagen;

      this.convocatoria.estado = this.estadoa.id;
      console.log(this.convocatoria)

      if (this.convocatoria.entrada_id) {
        //formData.append("_method", "PUT");
        axios
          .put(
            `/convocatorias/${this.convocatoria.entrada_id}`,
            this.convocatoria
          )
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post("/convocatorias", this.convocatoria)
          .then((response) => {
            this.success("Insertado con Exito");
            // router.push({ name: 'convocatorias' });
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },
    date() {
      const hoy = new Date();
      var date =
        hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
      var time = hoy.getHours() + ":" + hoy.getMinutes();
      this.convocatoria.published_at = date + " " + time;
    },

    recibirMedia(media) {
      if (media.tipo == "IMAGEN") {
        //this.$refs.ckeditor.editorCk
        const content = `<figure class="image"><img src="${media.url}"></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else if (media.tipo == "VIDEO") {
        if (media.enlace) {
          const content = `<figure class="media"><oembed url="${media.url}"></oembed></figure>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =`
            <div class="raw-html-embed" style="text-align:center;">
              <video width="540" controls>
                <source src="${media.url}" type="video/mp4">
              </video>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='AUDIO'){
        if (media.enlace) {
           const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <iframe src="${media.url}" width="50%" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <audio controls="">
                <source src="${media.url}" type="audio/mpeg">
              </audio>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='DOCUMENTO'){
        const content =` 
          <div class="raw-html-embed" style="text-align:center;">
            <iframe src="${media.url}" width="80%" height="720px"></iframe>
          </div>`;
        const viewFragment =
        this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
      }else{
        const content = `<a href="${media.url}">${media.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      }
    },
    // RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen) {
      this.imagen = imagen.url;
    },
    recibirDocumento(document) {
      //console.log(document);
      this.convocatoria.url_documento = document.url;
      //console.log(this.convocatoria.url_documento);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "@core/scss/vue/libs/map-leaflet.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Buscar";
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>
