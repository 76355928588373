var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper",attrs:{"title":_vm.formTitle}},[_c('validation-observer',{ref:"convocatoriaRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"text-center"},[_c('b',[_vm._v("Foto Portada")])]),_c('div',{staticClass:"text-center"},[_c('b-form-group',{attrs:{"label":"","label-for":""}},[_c('div',{staticClass:"box-imagen box-imagen1 border rounded"},[(_vm.imagen)?_c('b-img',{staticClass:"rounded",attrs:{"src":_vm.imagen,"center":"","fluid":"","thumbnail":"","alt":"Card image cap"}}):_vm._e()],1)]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-seleccionar-imagen",modifiers:{"modal-seleccionar-imagen":true}}],attrs:{"variant":"flat-dark","size":"sm"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ImageIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Elegir Imagen")])],1)],1)])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"titulo"}},[_c('label',{attrs:{"for":"titulo"}},[_vm._v("Titulo Convocatoria "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Titulo convocatoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"titulo","state":errors.length > 0 ? false : null},model:{value:(_vm.convocatoria.titulo),callback:function ($$v) {_vm.$set(_vm.convocatoria, "titulo", $$v)},expression:"convocatoria.titulo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.titulo),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"Url"}},[_c('label',{attrs:{"for":"url"}},[_vm._v("Url "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-input',{attrs:{"id":"url","disabled":"","placeholder":"Url"},model:{value:(_vm.convocatoria.url),callback:function ($$v) {_vm.$set(_vm.convocatoria, "url", $$v)},expression:"convocatoria.url"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"Fecha Publicacion"}},[_c('label',{attrs:{"for":"Fecha Publicacion"}},[_vm._v("Fecha Publicación "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Fecha Publicacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: 'es'}},model:{value:(_vm.convocatoria.published_at),callback:function ($$v) {_vm.$set(_vm.convocatoria, "published_at", $$v)},expression:"convocatoria.published_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.published_at),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"Estado"}},[_c('v-select',{attrs:{"id":"blog-edit-estado","label":"title","disabled":!_vm.$hascan('convocatoria.publicar'),"options":_vm.estadoOpciones,"clearable":false},model:{value:(_vm.estadoa),callback:function ($$v) {_vm.estadoa=$$v},expression:"estadoa"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"descripcion"}},[_c('label',{attrs:{"for":"Descripcion"}},[_vm._v("Descripción")]),_c('CkEditorPersonalizado',{ref:"ckeditor",model:{value:(_vm.convocatoria.descripcion),callback:function ($$v) {_vm.$set(_vm.convocatoria, "descripcion", $$v)},expression:"convocatoria.descripcion"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":""}},[_c('label',{attrs:{"for":"Contenido"}},[_vm._v("Contenido")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-seleccionar-archivo",modifiers:{"modal-seleccionar-archivo":true}}],attrs:{"variant":"flat-dark","size":"sm"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ImageIcon"}}),_c('span',{staticClass:"align-left"},[_vm._v("Medios")])],1),_c('validation-provider',{attrs:{"name":"Contenido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CkEditorPersonalizado',{ref:"ckeditor",model:{value:(_vm.convocatoria.contenido),callback:function ($$v) {_vm.$set(_vm.convocatoria, "contenido", $$v)},expression:"convocatoria.contenido"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.contenido),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"pt-2"},[_vm._v("Fechas de Recepcion")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"fecha"}},[_c('label',{attrs:{"for":"fecha"}},[_vm._v("Fecha Inicio "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Fecha Inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"fecha","placeholder":"Ingrese la Fecha Inicio","local":"es","state":errors.length > 0 ? false : null},model:{value:(_vm.convocatoria.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.convocatoria, "fecha_inicio", $$v)},expression:"convocatoria.fecha_inicio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.fecha_inicio),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"fecha_final"}},[_c('label',{attrs:{"for":"fecha_final"}},[_vm._v("Fecha Final "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Fecha Final","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"fecha_final","placeholder":"Ingrese la Fecha Final","local":"es","state":errors.length > 0 ? false : null},model:{value:(_vm.convocatoria.fecha_final),callback:function ($$v) {_vm.$set(_vm.convocatoria, "fecha_final", $$v)},expression:"convocatoria.fecha_final"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.fecha_final),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Detalle del Lugar Presentación")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"nombre_lugar"}},[_c('label',{attrs:{"for":"nombre_lugar"}},[_vm._v("Nombre del Lugar ")]),_c('b-form-input',{attrs:{"id":"nombre_lugar"},model:{value:(_vm.convocatoria.nombre_lugar),callback:function ($$v) {_vm.$set(_vm.convocatoria, "nombre_lugar", $$v)},expression:"convocatoria.nombre_lugar"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"direccion"}},[_c('label',{attrs:{"for":"direccion"}},[_vm._v("Dirección ")]),_c('b-form-input',{attrs:{"id":"direccion"},model:{value:(_vm.convocatoria.direccion_presentacion),callback:function ($$v) {_vm.$set(_vm.convocatoria, "direccion_presentacion", $$v)},expression:"convocatoria.direccion_presentacion"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',{},[_vm._v("Mas Informaciones")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Teléfono","label-for":"telefono_informes"}},[_c('validation-provider',{attrs:{"name":"Telefono de Informaciones","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"telefono_informes","state":errors.length > 0 ? false : null},model:{value:(_vm.convocatoria.telefono_informes),callback:function ($$v) {_vm.$set(_vm.convocatoria, "telefono_informes", $$v)},expression:"convocatoria.telefono_informes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.telefono_informes),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"URL Documento","label-for":"url_documento"}},[_c('validation-provider',{attrs:{"name":"URL Documento","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"url_documento","state":errors.length > 0 ? false : null},model:{value:(_vm.convocatoria.url_documento),callback:function ($$v) {_vm.$set(_vm.convocatoria, "url_documento", $$v)},expression:"convocatoria.url_documento"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-seleccionar-documento",modifiers:{"modal-seleccionar-documento":true}}],attrs:{"variant":"outline-primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"LinkIcon"}}),_c('span',{staticClass:"align-left"})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.url_documento),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"URL Formulario","label-for":"url_formulario"}},[_c('validation-provider',{attrs:{"name":"URL Formulario","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url_formulario","state":errors.length > 0 ? false : null},model:{value:(_vm.convocatoria.url_formulario),callback:function ($$v) {_vm.$set(_vm.convocatoria, "url_formulario", $$v)},expression:"convocatoria.url_formulario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.url_formulario),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.validar}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","to":{ name: 'convocatorias' }}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('b-row'),_c('MediosComponent',{on:{"enviarMedia":_vm.recibirMedia}}),_c('ImagenComponent',{on:{"enviarImagen":_vm.recibirImagen}}),_c('DocumentoComponent',{on:{"enviarDocumento":_vm.recibirDocumento}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }